import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { db } from "../../firebase";
import { useLayoutEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { compareAsc, format } from 'date-fns'
import Tooltip from '@mui/material/Tooltip';




const Single = (props) => {

  const {currentUser} = useContext(AuthContext)

  console.log("currentUser from context  : "+JSON.stringify(currentUser));


  const location = useLocation();
  console.log(props, " props");
  console.log(location, " useLocation Hook");

  const data = location.state?.data;

  console.log("data=" + JSON.stringify(data));


  // const getData = async () => {
  //   const docRef = doc(db, "users", "i43yNh4nZwcyyFs8yqkFdimlxuM2");
  //   const dataOne=[];
  //   try {
  //     const docSnap = await getDoc(docRef);
  //     console.log("obeeeeeeeeeeeeeeeeeeeeeeeee");
  //     if (docSnap.exists()) {
  //       console.log("docSnap + " + JSON.stringify(docSnap.data()));
  //        dataOne=[];
  //       dataOne[0]= docSnap.data()
  //       data= dataOne;
  //     } else {
  //       console.log("Document does not exist")
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  useEffect(() => {
    document.title = "Your Profile";
    console.log("in effect");
    const keyvalues = JSON.stringify(Object.keys(data[0]).map(key => key + ":" + data[0][key]))
    console.log("process.env.ADMIN_EMAIL : "+process.env.ADMIN_EMAIL)

    console.log("keyvalues" + keyvalues);

  }, []);

  return (
    <div className="single">
       {/*<Sidebar />*/}
     
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit : {currentUser.email}</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src={data ? data[0].img : "No Value"}
                alt=""
                className="itemImg"
              />


              <div className="details">
                <h1 className="itemTitle">{data ? data[0].username : "No Value"}</h1>
                <div>




                </div>

                <div className="detailItem">
                  <strong><span className="itemKey">Birthday:</span></strong>
                  <Tooltip title={data && data[0].birthday ? format(new Date(data[0].birthday), 'yyyy MMM dd (h:mm a)') : "No Value"} placement="right">
                  <span className="itemValue">{data && data[0].birthday ? format(new Date(data[0].birthday), 'yyyy MMM dd ') : "No Value"}</span>
                  </Tooltip>
                </div>

                <div className="detailItem">
                <strong><span className="itemKey">Gotra:</span></strong>
                  <span className="itemValue">{data ? data[0].gotra : "No Value"}</span>
                </div>

                <div className="detailItem">
                  <span className="itemKey">Father Name:</span>
                  <span className="itemValue">{data ? data[0].fathername : "No Value"}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data ? data[0].email : "No Value"}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Birth Place:</span>
                  <span className="itemValue">{data ? data[0].place : "No Value"}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Height:</span>
                  <span className="itemValue">{data ? data[0].height : "No Value"}</span>
                </div>

                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data ? data[0].phone : "No Value"}</span>
                </div>
                
                <div className="detailItem">
                  <span className="itemKey">Education:</span>
                  <span className="itemValue">{data ? data[0].education : "No Value"}</span>
                </div>

              <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    {data ? data[0].address : "No Value"}
                  </span>
               </div>

               




              </div>

              
            </div>
          </div>

        </div>
          {/* divTHIS ONE IS A VALID COMMENT */}
      </div>
    </div>
  );
};

export default Single;
