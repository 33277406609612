import { useContext, useState,useEffect } from "react";
import "./login.scss";
import { signInWithEmailAndPassword,signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext"
import  { useLayoutEffect } from "react";
import { Link } from "react-router-dom";



const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navitage = useNavigate()

  const {dispatch} = useContext(AuthContext)

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        console.log("signInWithEmailAndPassword: "+JSON.stringify(userCredential))
        const user = userCredential.user;
        dispatch({type:"LOGIN", payload:user})
         navitage("/")
      })
      .catch((error) => {
        setError(true);
      });
  };

  const handleLogOut = (e) => {
    e.preventDefault();

    signOut(auth)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({type:"LOGOUT", payload:user})
        navitage("/")
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
    
   
    //localStorage.setItem("user", JSON.stringify(state.currentUser));
   
  });

  useLayoutEffect(() => {
    document.title = `SSK Savji`;
    localStorage.removeItem("user");
    dispatch({type:"LOGOUT"})
    console.log('UseLayoutEffect is called with the value of' );
  }, []);

  return (

   
    
    
    <div className="login">


     
  

      
      <form onSubmit={handleLogin}>
    
      {error && <span>Wrong email or password!</span>}
        <input
          type="email"
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
         <br></br>
        <div>No account?  <Link to="/register" className="link">
        Add New Profile
      </Link>  </div>

        
      </form>
    </div>
  );
};

export default Login;
