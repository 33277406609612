import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    document.title = "Dash Board";
   }, []);
   
  return (
    <div className="home">
      {/*<Sidebar />*/}
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
        {/*  <Widget type="product" />
          <Widget type="order" />
  <Widget type="earning" /> */}
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div>
       {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
  </div>*/}
      </div>
    </div>
  );
};

export default Home;
