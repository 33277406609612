export const userInputs = [
    {
      id: "username",
      label: "Username",
      type: "text",
      placeholder: "john_doe",
  
      required: true,
    },
    {
      id: "displayName",
      label: "Display Name",
      type: "text",
      placeholder: "John Doe",
      required: true,
    },
    {
      id: "fathername",
      label: "Father Name",
      type: "text",
      placeholder: "Father Name",
      required: true,
    },
    {
      id: "birthday",
      name: "birthday",
      type: "datetime-local",
      placeholder: "Birthday",
      label: "Birthday",
      required: true,
    },
    {
      id: "height",
      name: "Height",
      type: "number",
      placeholder: "Height",
      label: "Height",
      required: true,
      step:"any"
    },
    {
      id: "education",
      name: "Education",
      type: "text",
      placeholder: "Education",
      label: "Education",
    },
    
    {
      id: "email",
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
      required: true,
    },

    {
      id: "place",
      label: "Place",
      type: "text",
      placeholder: "Place of birth",
      required: true,
    },
    {
      id: "gotra",
      label: "Gotra",
      type: "text",
      placeholder: "Gotra",
    
    },
  
    {
      id: "nakshatra",
      label: "Nakshatra",
      type: "text",
      placeholder: "Nakshatra",
     
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    {
      id: "address",
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
   
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  