import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: "tutorial-5a2cf.firebaseapp.com",
//   projectId: "tutorial-5a2cf",
//   storageBucket: "tutorial-5a2cf.appspot.com",
//   messagingSenderId: "585126334212",
//   appId: "1:585126334212:web:8539eafc56885e1b4c4a51"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAZ3Wfa-PzS3dzYGx9Zw2d_oFgq4T63UBQ",
  authDomain: "dubai-f7dd8.firebaseapp.com",
  projectId: "dubai-f7dd8",
  storageBucket: "dubai-f7dd8.appspot.com",
  messagingSenderId: "844007189507",
  appId: "1:844007189507:web:f9f5b59b98f5b511a47236",
  measurementId: "G-CEHDWSGEXR"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
