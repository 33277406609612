import "./navbar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);


  const navitage = useNavigate()
  const userId = JSON.parse(localStorage.getItem('user')).uid;
  console.log("userId" + userId);

  const getData = async () => {
    const docRef = doc(db, "users", userId);
    const dataOne = [];
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("docSnap + " + JSON.stringify(docSnap.data()));
        navitage("/users/" + userId, { state: { data: [docSnap.data()] } });
      } else {
        console.log("Document does not exist")
      }

    } catch (error) {
      console.log(error)
    }
  }
  function sayHello() {
    console.log("userId" + userId);
    getData();
    // alert('Hello!');
  }


  const user = JSON.parse(localStorage.getItem('user')).providerData[0].email;

  return (
    
    <div className="navbar">
      <div className="wrapper">

       
        <div className="search">
          SSK Savji.in
        </div>
        <ul>


        <div className="items">

          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>

          
          <div className="item">
            <DashboardIcon className="icon" />
            <Link to="/" style={{ textDecoration: "none" }}>
              <div class="hover:text-green-50">Dashboard</div>
            </Link>
          </div>

          <div className="item" >
            <PersonOutlineIcon className="icon" />
            <Link to="/users" style={{ textDecoration: "none" }}>
              All Profiles
            </Link>
          </div>

          <div className="item" onClick={sayHello}>
            <AccountCircleOutlinedIcon className="icon" />
            <Link to="">My Profile</Link>


          </div>


        
          <div class="item" >
            {user}
          </div>
          <div class="item" >
            <ExitToAppIcon className="icon" />
            <Link to="/login" style={{ textDecoration: "none" }}>
              Logout
            </Link>
          </div>
        </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
