import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { db } from "../../firebase";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [single, setSingle] = useState([]);
  const [admin, setAdmin] = useState('k@k.com');

  const {currentUser} = useContext(AuthContext)
  

  useEffect(() => {
    // const fetchData = async () => {
    //   let list = [];
    //   try {
    //     const querySnapshot = await getDocs(collection(db, "users"));
    //     querySnapshot.forEach((doc) => {
    //       list.push({ id: doc.id, ...doc.data() });
    //     });
    //     setData(list);
    //     console.log(list);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchData();

    // LISTEN (REALTIME)
    const unsub = onSnapshot(
      collection(db, "users"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();


    };
  }, []);


  
  const handleDelete = async (id) => {
    try {
      console.log("delete Id : "+ id);
      console.log("my dtata"+ JSON.stringify(doc(db, "users", id)))
      await deleteDoc(doc(db, "users", id));
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
     
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {

        const single = data.filter((item) => item.id === params.row.id);
        console.log("single"+ JSON.stringify(single));

        return (
          <div className="cellAction">
            <Link to={'/users/'+params.row.id} state={ 
               { data: data.filter((item => item.id === params.row.id))
               
               } 
               } style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
           { currentUser.email===admin && <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>}
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Profile
        <Link to="/users/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[14]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
